<template>
<CRow>
    <CCol col="12" xl="12">
        <CCard>
            <CCardHeader>
                <CCol col="6" style="font-size: 25px;">عرض الفئة</CCol>
            </CCardHeader>
            <CCardBody>
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-bordered">
                                <tr >
                                    <th>Name</th>
                                    <td>{{ category.name }}</td>
                                </tr>
                                <tr>
                                    <th>الاسم</th>
                                    <td>{{ category.name_ar }}</td>
                                </tr>
                                <tr>
                                    <th>الصورة</th>
                                    <td>
                                        <img v-bind:src="category.image_full_path" style="height: 100px;width: 100px; margin-right: 15px;"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <CButton color="warning" @click="goBack">رجوع</CButton>
                </div>
            </CCardBody>
        </CCard>
    </CCol>
</CRow>
</template>

<script>
    export default {
        name : 'ShowCategory',
        data() {
            return {
                category: [],
                image:''
      }
        },
        created() {
            console.log(this.$route.params.id);
        this.$http
            .get(`${process.env.VUE_APP_URL}categories/${this.$route.params.id}/edit`)
            .then((response) => {
                console.log('response', response.data.data);
                this.category = response.data.data;

            });
        },
        methods: {
            goBack() {
                this.categoriesOpened ? this.$router.go(-1) : this.$router.push({path: '/categories'})
            }
        }
    }
</script>

<style scoped>
th {
    background-color: #3c4b64;
    color: #fff;
}
</style>
